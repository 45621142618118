.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 60%;
  /* Set the fixed height of the footer here */
  height: 30px;
  line-height: 30px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.navbar-brand {
  padding: 0rem;
  margin: 0rem;
}

.header {
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.card-container {
  padding: 0.25rem;
}
