.termdates table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid black;
}

.termdates th {
  font-size: 27px;
}

.termdates td {
  text-align: left;
  border-top: 1px solid black;
  font-size: 20px;
}

.emptyheader {
  background-color: #dddddd;
}

.tablenotes {
  font-size: 20px;
}

.divwiththinborder {
  border: 1px solid black;
}
/*.termdates tr:nth-child(odd) {
  background-color: #dddddd;
}*/
